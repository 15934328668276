import React, { useEffect, useState } from "react"
import { Flex, Space, Typography } from "antd"
import { CheckCircleTwoTone, CheckCircleOutlined } from "@ant-design/icons"

import { Button, Checkbox } from "antd"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { useGoogleLogin } from "@react-oauth/google"
import { useFormik } from "formik"

import {
  useFacebookSSOLoginMutation,
  useGoogleSSOLoginMutation,
  useRegisterMutation
} from "../../api/apiSlices/authApiSlice"

import { FacebookIcon, GoogleIcon, AppleIcon ,FACEBOOK } from "../../assets/rawSvgs/index"

import { Routes } from "../../constants/routes"

import styles from "../login/auth.module.scss"
import useRouterNavigation from "../../hooks/useNavigate"
import { setJWTToken, setRefreshToken, setStreamToken, setUser } from "../../api/auth"
import CustomInput from "../../components/customInput/CustomInput"
import { registerValidationSchema } from "../../schemas/RegisterValidationSchema"
import { openNotification } from "../../util/openNotifications"
import { useLocation } from "react-router-dom"
import RoleSelection from "./components/RoleSelection"
import { setUserRole } from "../../redux/slices/auth"
import { useDispatch } from "react-redux"
import { ROLES } from "../../constants/roles"
const { Text } = Typography

const Register = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const roleFromQuery = queryParams.get("role")

  const [register, { isLoading: isRegisterLoading, error: registerError }] =
    useRegisterMutation()
  const [
    facebookSSOLogin,
    { isLoading: isFacebookLoading, error: facebookError }
  ] = useFacebookSSOLoginMutation()

  const [googleSSOLogin, { isLoading: isGoogleLoading, error: googleError }] =
    useGoogleSSOLoginMutation()

  const [mainRole, setMainRole] = useState()
  const [isSubRoleNeeded, setIsSubRoleNeeded] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (roleFromQuery) {
      setMainRole(roleFromQuery)
      if (roleFromQuery === "flex-time") {
        setIsSubRoleNeeded(true)
      }
    }
  }, [roleFromQuery])

  const { navigateTo } = useRouterNavigation()

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const data = await googleSSOLogin({
          access_token: tokenResponse.access_token,
          role: mainRole
        }).unwrap()

        if (data?.tokens) {
          openNotification({
            type: "success",
            message: "Login Successful"
          })
          setJWTToken(data?.tokens?.access)
          setRefreshToken(data?.tokens?.refresh)
        setStreamToken(data?.stream_chat_token)
          setUser(data?.user)
          dispatch(setUserRole({ role: data?.user?.role }))
          
          if (data?.user?.role === ROLES.SOCIAL_USER) {
            navigateTo(Routes.HOME)
          } else if (data?.user?.role === ROLES.WORKER) {
            if (data?.user?.is_worker_onboarding_initiated) {
              navigateTo(Routes.WORKER_JOBS)
            } else {
              navigateTo(Routes.USER_EXPERIENCE)
            }
          } else if (data?.user?.role === ROLES.EMPLOYER) {
            navigateTo(Routes.CREATION)
          } else if (data?.user?.role === ROLES.DONOR) {
            navigateTo(Routes.DONATION)
          }

          
        
          
        }
      } catch (error) {
        console.error(error)
      }
    },
    onError: () => {
      console.log("Login Failed")
    }
  })

  const handleSuccess = async response => {
    try {
      const apiResponse = await facebookSSOLogin({
        access_token: response.access_token,
        role: mainRole
      }).unwrap()
      console.log(apiResponse.data)
      if (apiResponse?.data?.id) {
        navigateTo(`${Routes.LOGIN}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleFailure = error => {
    console.log("Facebook Login Failed", error)
  }

  const registerForm = {
    name: "",
    username: "",
    email: "",
    password: ""
  }

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur
  } = useFormik({
    initialValues: registerForm,
    validationSchema: registerValidationSchema,
    onSubmit: async values => {
      try {
        const data = await register({ ...values, role: mainRole }).unwrap()
        if (data?.id) {
          openNotification({
            type: "success",
            message: "You have been registered successfully"
          })
          navigateTo(`${Routes.LOGIN}`)
        }
      } catch (error) {
        openNotification({
          type: "error",
          message: error.message
        })
      }
    }
  })
  const onChangeMainRole = role => {
    if (role === "flex-time") {
      setIsSubRoleNeeded(true)
    } else {
      setIsSubRoleNeeded(false)
    }
    setMainRole(role)
  }

  if (!mainRole || isSubRoleNeeded)
    return (
      <RoleSelection mainRole={mainRole} onChangeMainRole={onChangeMainRole} />
    )

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div>
          <h4 className={styles.loginTitle}>Create an Account</h4>
          <p className={styles.helperText}>
            Welcome! Please enter your credentials.
          </p>
        </div>
        <div className={styles.socialIcons}>
          <div className={styles.loginIconsWrap} onClick={() => googleLogin()}>
            <GoogleIcon />
          </div>

          <FacebookLogin
            appId="1749896512211777"
            autoLoad={false}
            fields="name,email,picture"
            callback={handleSuccess}
            onFailure={handleFailure}
            render={renderProps => (
              <div
                className={styles.loginIconsWrap}
                onClick={renderProps.onClick}
              >
                <FACEBOOK/>
              </div>
            )}
          />
          <div className={styles.loginIconsWrap}>
            <AppleIcon />
          </div>
        </div>
        <div className={styles.orDivider}>
          <hr className={styles.line} />
          <p>OR</p>
          <hr className={styles.line} />
        </div>
        <form onSubmit={handleSubmit}>
          <Space direction="vertical" size="middle">
            <Flex gap={12}>
              <CustomInput
                inputwd="w-50"
                placeholder="Enter Name"
                label="Name"
                className="mb-3"
                onBlur={handleBlur}
                value={values?.name}
                onChange={e => setValues({ ...values, name: e.target.value })}
                type="text"
                errorMessage={touched.name && errors?.name}
              />
              <CustomInput
                inputwd="w-50"
                placeholder="Enter Username"
                label="Username"
                className="mb-3"
                onBlur={handleBlur}
                value={values?.username}
                onChange={e =>
                  setValues({ ...values, username: e.target.value })
                }
                type="email"
                errorMessage={touched.username && errors?.username}
              />
            </Flex>
            <CustomInput
              placeholder="Email"
              label="Email"
              className="mb-3"
              value={values?.email}
              onBlur={handleBlur}
              onChange={e => setValues({ ...values, email: e.target.value })}
              type="email"
              errorMessage={touched.email && errors?.email}
            />
            <CustomInput
              placeholder="Password"
              label="Password"
              onBlur={handleBlur}
              value={values?.password}
              onChange={e => setValues({ ...values, password: e.target.value })}
              type="password"
              errorMessage={touched.password && errors?.password}
            />
          </Space>
          {/* <div className="d-block mt-12 mb-12">
            <Checkbox className="text-xs-500">Remember me</Checkbox>
          </div>
          <Space direction="vertical" size={6}>
            <Flex gap="small">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <Text className="font-weight-500">
                Must be a minimum of 8 characters
              </Text>
            </Flex>
            <Flex gap="small">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <Text className="font-weight-500">
                Must contain an upper case letter
              </Text>
            </Flex>
            <Flex gap="small">
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <Text className="font-weight-500">
                Must contain lower case letter
              </Text>
            </Flex>
            <Flex gap="small">
              <CheckCircleOutlined color="#00000073" />
              <CheckCircleTwoTone twoToneColor="#52c41a" disabled />
              <Text className="font-weight-500" disabled>
                Must contain at least one number
              </Text>
            </Flex>
          </Space> */}
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="mt-4"
            block
          >
            Get Started
          </Button>
        </form>
        <p className={styles.termsText}>
          By creating an account, you agree to the
          <span onClick={() => navigateTo(Routes.TERMS_AND_CONDITION)}>
            Terms of Service and Privacy Policy
          </span>
        </p>
      </div>
    </div>
  )
}

export default Register
