import { Routes } from '../constants/routes';
import Community from '../pages/community/Community';
import JobDetails from '../pages/job-detail/JobDetails';
import Profile from '../pages/profile/Profile';
import Settings from '../pages/settings';
import SocialMedia from '../pages/social-media';
import SelectPlatform from '../pages/switch-platform';
import UserExperience from '../pages/user-experience/UserExperience';
import WorkerProfile from '../pages/worker-profile';
import JobCreation from '../pages/gig-creation/JobCreation';
import FlexTimeDashboard from '../pages/flextime-dashboard/FlexTimeDashboard';
import Communities from '../pages/communities/Communities';
import ChatManage from '../pages/chat-management/ChatManage';
import AllEmployerJobs from '../pages/employer-jobs/AllJobs';
import AllWorkerJobs from '../pages/worker-jobs/AllJobs';
import Donation from '../pages/donations/Donation';
import SocialPost from '../pages/social-media-post/Post';
import ContactUs from '../pages/contact-us';

export const common = [
  {
    path: Routes.SETTINGS,
    component: Settings,
  },
  {
    path: Routes.SELECT_PLATFORM,
    component: SelectPlatform,
  },
  {
    path: Routes.CHAT,
    component: ChatManage,
  },
];

export const socialMediaRoutes = [
  {
    path: Routes.PROFILE,
    component: Profile,
  },
  {
    path: Routes.HOME,
    component: SocialMedia,
  },
  {
    path: Routes.COMMUNITY,
    component: Community,
  },
  {
    path: Routes.SETTINGS,
    component: Settings,
  },
  {
    path: Routes.COMMUNITIES,
    component: Communities,
  },
  {
    path: Routes.SOCIAL_MEDIA_POST,
    component: SocialPost,
  },
  {
    path: Routes.CONTACT_US,
    component: ContactUs,
  },
  ...common,
];

export const donationRoutes = [
  {
    path: Routes.DONATION,
    component: Donation,
  },
  {
    path: Routes.SELECT_PLATFORM,
    component: SelectPlatform,
  },
  {
    path: Routes.SETTINGS,
    component: Settings,
  },
];

export const flexTimeWorkerRoutes = [
  {
    path: Routes.USER_EXPERIENCE,
    component: UserExperience,
  },
  {
    path: Routes.WORKER_PROFILE,
    component: WorkerProfile,
  },
  {
    path: Routes.DASHBOARD,
    component: FlexTimeDashboard,
  },
  {
    path: Routes.CHAT,
    component: ChatManage,
  },
  {
    path: Routes.JOB_DETAIL,
    component: JobDetails,
  },
  {
    path: Routes.WORKER_JOBS,
    component: AllWorkerJobs,
  },
  {
    path: Routes.USER_EXPERIENCE_EDIT,
    component: UserExperience,
  },
  ...common,
];

export const flexTimeEmployerRoutes = [
  {
    path: Routes.WORKER_PROFILE,
    component: WorkerProfile,
  },
  {
    path: Routes.EMPLOYER_JOBS,
    component: AllEmployerJobs,
  },
  {
    path: Routes.JOB_DETAIL,
    component: JobDetails,
  },
  {
    path: Routes.DASHBOARD,
    component: FlexTimeDashboard,
  },
  {
    path: Routes.CREATION,
    component: JobCreation,
  },
  {
    path: Routes.WORKER_PROFILE,
    component: WorkerProfile,
  },
  ...common,
];
