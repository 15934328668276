import { useNavigate } from 'react-router-dom';

export const userHasValidToken = () => {
  const token = getJWTToken();

  return !!token;
};

export const setJWTToken = (token) => {
  window.localStorage.setItem('token', token);
};

export const setStreamToken = (token) => {
  window.localStorage.setItem('streamToken', token);
};

export const setUserId = (id) => {
  window.localStorage.setItem('userId', id);
};

export const setUser = (user) => {
  window.localStorage.setItem('user', JSON.stringify(user));
};

export const getJWTToken = () => {
  return window.localStorage.getItem('token');
};

export const getStreamToken = () => {
  return window.localStorage.getItem('streamToken');
};

export const getUserId = () => {
  return window.localStorage.getItem('userId');
};

export const getUser = () => {
  const user = JSON.parse(window.localStorage.getItem('user'));
  if (user) return user;
  window.location.href = '/login';
};

export const removeJWTToken = () => {
  window.localStorage.removeItem('token');
};

export const removeUser = () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('redux-role-persist');
};

export const setRefreshToken = (token) => {
  window.localStorage.setItem('refreshToken', token);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem('refreshToken');
};

export const removeRefreshToken = () => {
  window.localStorage.removeItem('refreshToken');
};

export const setlocalStorage = (name, value) => {
  window.localStorage.setItem(name, value);
};

export const getlocalStorage = (name) => {
  window.localStorage.getItem(name);
};
