import {
  DisconnectOutlined,
  ExclamationCircleOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Typography,
  Flex,
  Button,
  Col,
  Spin,
  Upload,
  Modal,
  Empty,
} from 'antd';
import Title from 'antd/es/typography/Title';
import styles from './profile.module.scss';
import UserConnectionManagment from './components/user-connection/UserConnectionManagment';
import PostCard from '../social-media/components/postCard/PostCard';
import SuggestionsList from '../social-media/components/suggestionsList/suggestionsList';

import {
  useAddUserConnectionMutation,
  useCancelConnectionReqMutation,
  useCreateSocialUserChatMutation,
  useDisconnectUserMutation,
  useGetSocialMediaProfileQuery,
  useUpdateSocialMediaProfileMutation,
} from '../../api/apiSlices/socialMediaApiSlice';

import {
  AddUserIcon,
  BubbleChatIcon,
  LockIcon,
  Yellow28EditIcon,
} from '../../assets/rawSvgs';
import { useParams } from 'react-router-dom';
import { getUser } from '../../api/auth';
import { openNotification } from '../../util/openNotifications';
import { useAddMediaMutation } from '../../api/apiSlices/settingsApiSlice';
import useRouterNavigation from '../../hooks/useNavigate';
import { Routes } from '../../constants/routes';
import { useFeed } from '../../hooks/useFeed';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { apiSlice } from '../../api/apiSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const { Text } = Typography;

const Profile = () => {
  const currentUser = getUser();
  let { id: userId } = useParams();

  const { navigateTo } = useRouterNavigation();
  const [profileImageUploading, setProfileImageUploading] = useState(false);

  const isCurrentUserProfile = currentUser.id === parseInt(userId);
  const [addMedia] = useAddMediaMutation();
  const [createChat, { isLoading: isChatLoading }] =
    useCreateSocialUserChatMutation();
  const [cancelReq] = useCancelConnectionReqMutation();
  const dispatch = useDispatch();

  const {
    loadMore: loadMoreFeedItems,
    posts: socialFeed,
    loading: isLoadingFeed,
    hasMore: hasMoreFeedItems,
    handleComment: addCommentToPost,
    toggleLike: togglePostLike,
    handleRejectCommunityPost,
    isAddingComment,
  } = useFeed({
    feedId: isCurrentUserProfile ? 'self' : userId,
    feedType: 'social_feed',
  });

  const [disconnectUser, { isLoading: isDisconnecting }] =
    useDisconnectUserMutation();

  const {
    data: userProfile,
    isLoading: isProfileLoading,
    refetch: refetchUserProfile,
  } = useGetSocialMediaProfileQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  const [updateProfile] = useUpdateSocialMediaProfileMutation();
  const [addUserConnection] = useAddUserConnectionMutation();

  const [modal, contextHolder] = Modal.useModal();

  const { lastElementRef } = useInfiniteScroll({
    onIntersect: () => {
      if (isLoadingFeed) return;
      if (hasMoreFeedItems) {
        loadMoreFeedItems();
      }
    },
    enabled: !isLoadingFeed && socialFeed?.length > 0,
  });

  const handleCreateChat = async () => {
    try {
      const payload = {
        member_id: userId,
      };
      const chat = await createChat(payload).unwrap();
      navigateTo(`${Routes.CHAT}?channel_id=${chat?.channel_id}`);
      openNotification({
        type: 'success',
        message: 'Chat created successfully!',
      });
    } catch (error) {
      openNotification({
        type: 'error',
        message:
          error?.message ||
          error?.errors ||
          'Unexpected error occurred. Please try again.',
      });
    }
  };

  const handleUpload = async ({ file }) => {
    setProfileImageUploading(true);
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (!allowedTypes.includes(file.type)) {
      openNotification({
        type: 'error',
        message: 'Inavlid file type. Please upload an image file(JPEG or PNG) ',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      const data = await addMedia(formData).unwrap();
      updateProfile({ profile_picture: data.object_key })
        .unwrap()
        .then(() => {
          dispatch(
            apiSlice.util.invalidateTags([
              { type: 'social_profile', id: userId },
            ]),
          );
        });
    } catch (error) {
      openNotification({ type: 'error', message: error?.message });
    } finally {
      setProfileImageUploading(false);
    }
  };

  if (isProfileLoading || !userProfile) {
    return (
      <Flex align="center" justify="center" style={{ height: '100vh' }}>
        <Spin spinning />
      </Flex>
    );
  }

  const onAddConnection = async (id) => {
    const payload = {
      connected_user: id,
    };
    await addUserConnection(payload)
      .unwrap()
      .then(() => {
        refetchUserProfile();
        openNotification({
          message: 'A connection request has been sent',
          type: 'success',
        });
      });
  };

  const onRejectConnectionReq = async (id) => {
    await disconnectUser(id)
      .unwrap()
      .then(() => {
        refetchUserProfile();
        openNotification({
          type: 'success',
          message: 'Connection disconnected',
        });
      });
  };

  const onDisconnectUser = () => {
    modal.confirm({
      title: 'Diconnect',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to disconnect ?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      okButtonProps: {
        danger: true,
        loading: isDisconnecting,
        disabled: isDisconnecting,
      },
      onOk: () => onRejectConnectionReq(userProfile.user_connection_id),
      centered: true,
    });
  };

  const cancelConnectionReq = async () => {
    if (isCurrentUserProfile) return;
    await cancelReq({ recipient_id: userProfile.user_id })
      .unwrap()
      .then(() => {
        refetchUserProfile();
        openNotification({
          type: 'success',
          message: 'Connection request removed',
        });
      })
      .catch((error) => {
        openNotification({ type: 'error', message: error?.message });
      });
  };

  console.log('userProfile', userProfile);
  return (
    <div className={styles.ProfileContainer}>
      <Flex justify="space-between" align="start">
        <Flex gap="large" style={{ marginBottom: '16px' }}>
          <Spin spinning={profileImageUploading}>
            <div className={styles.editProfileImage}>
              <Avatar
                size={100}
                src={userProfile?.profile_picture?.url}
                icon={!userProfile?.profile_picture?.url && <UserOutlined />}
                style={{ backgroundColor: '#3DC2DC' }}
              />
              {isCurrentUserProfile && (
                <span className={styles.editProfileIcon}>
                  <Upload
                    customRequest={handleUpload}
                    showUploadList={false}
                    accept="image/*"
                  >
                    <Yellow28EditIcon />
                  </Upload>
                </span>
              )}
            </div>
          </Spin>
          <Flex vertical gap="large">
            <div>
              <Title level={5}>{userProfile.name || 'Anonymous'}</Title>
              <Flex align="center" gap={12}>
                <Text type="secondary" className="text-lg-400">
                  @{userProfile.username || 'Anonymous'}
                </Text>
                {userProfile.age && (
                  <>
                    <div className="dot dot_small"></div>
                    <Text type="secondary" className="text-lg-400">
                      Age: {userProfile.age}
                    </Text>
                  </>
                )}

                {userProfile.gender && (
                  <>
                    <div className="dot dot_small" />
                    <Text type="secondary" className="text-lg-400">
                      {userProfile.gender}
                    </Text>
                  </>
                )}
              </Flex>
            </div>
            {isCurrentUserProfile ? (
              <UserConnectionManagment />
            ) : userProfile?.privacy_settings?.show_friends_list ? (
              <UserConnectionManagment userId={userId} isCurrentUser={false} />
            ) : null}
          </Flex>
        </Flex>
        {!isCurrentUserProfile && (
          <Flex gap={12} align="center">
            {!userProfile?.is_user_connected &&
              !userProfile?.is_request_sent && (
                <Button
                  htmlType="text"
                  size="large"
                  icon={<AddUserIcon />}
                  onClick={() => onAddConnection(userProfile.user_id)}
                >
                  Add Friend
                </Button>
              )}
            {userProfile?.is_user_connected && (
              <Button
                type="default"
                danger
                size="large"
                icon={<DisconnectOutlined />}
                onClick={() => {
                  onDisconnectUser();
                }}
              >
                Disconnect
              </Button>
            )}
            {contextHolder}
            {!userProfile?.is_user_connected &&
              userProfile?.is_request_sent && (
                <Button
                  htmlType="text"
                  size="large"
                  icon={<UserDeleteOutlined />}
                  onClick={cancelConnectionReq}
                >
                  Cancel Request
                </Button>
              )}
            <Button
              htmlType="text"
              size="large"
              onClick={handleCreateChat}
              icon={<BubbleChatIcon color="rgba(0, 0, 0, 0.88)" />}
              disabled={isChatLoading}
            >
              Message
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex vertical>
        {userProfile.bio && (
          <Text style={{ fontSize: '16px' }}>{userProfile.bio}</Text>
        )}
      </Flex>
      <div className={styles.profileScreenScroll}>
        {!isCurrentUserProfile && userProfile.is_profile_locked && (
          <div className={styles.lockCard}>
            <LockIcon />
            <Text className="text-lg-500">
              {userProfile.username} Locked her Profile
            </Text>
          </div>
        )}

        <Flex gap={24} justify="space-between">
          <Col span={16}>
            <Spin spinning={isLoadingFeed} style={{ marginTop: '50px' }}>
              {(isCurrentUserProfile ||
                (!isCurrentUserProfile && !userProfile.is_profile_locked)) && (
                <>
                  {socialFeed
                    ?.filter((item) => !item?.community)
                    ?.map((feed, index) => {
                      return (
                        <PostCard
                          index={feed?.post_id}
                          attachments={feed?.attachments}
                          author={feed?.author}
                          comments={feed?.comments}
                          comments_count={feed?.comments_count}
                          comments_enabled={feed?.comments_enabled}
                          content={feed?.content}
                          created_at={feed?.created_at}
                          likes_count={feed?.likes_count}
                          post_id={feed?.post_id}
                          likes_enabled={feed?.likes_enabled}
                          user_has_liked={feed?.user_has_liked}
                          likes={feed?.likes}
                          feed={feed}
                          togglePostLike={togglePostLike}
                          addCommentToPost={addCommentToPost}
                          isAddCommentToPostLoading={isAddingComment}
                          key={feed?.post_id}
                          onRemoveCommunityPost={handleRejectCommunityPost}
                          ref={
                            index === socialFeed.length - 1
                              ? lastElementRef
                              : null
                          }
                          isCommunityView
                        />
                      );
                    })}
                  {!isLoadingFeed && socialFeed.length === 0 && (
                    <Empty description="No Posts to display" />
                  )}
                </>
              )}
            </Spin>
          </Col>
          <Col span={6}>
          <div style={{position:"fixed"}}>
            <SuggestionsList />
            </div>
          </Col>
        </Flex>

        {/* <Step1/> */}
      </div>
    </div>
  );
};

export default Profile;
