import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import ViewSaved from './components/ViewSaved';
import ToneSelector from './components/ToneSelector';
import InputBar from './components/InputBar';
import ContentGenerated from './components/ContentGenerated';
import { SaveIcon } from '../../../../assets/rawSvgs';
import { Space, Layout, Divider } from 'antd';
import { Typography } from 'antd';
import { getUser } from '../../../../api/auth';
import {
  useChatMutation,
  useGetChatsQuery,
  useDeleteChatMutation,
} from '../../../../api/apiSlices/communityApiSlice';

const { Title } = Typography;

function PopoverContent({ closePopover, handleInsertion, conversationId }) {
  const [chat] = useChatMutation();

  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [results, setResults] = useState([]);

  const [saveItems, setSaveItems] = useState([]);
  const [showSavedItems, setShowSavedItems] = useState(false);
  const [toneSelector, setToneSelector] = useState(false);
  let [status, setStatus] = useState(true);

  const toggleSavedItemsView = () => {
    setShowSavedItems((prevState) => !prevState);
  };

  const handleSearch = async (searchValue) => {
    setSearchValue(searchValue);
    setIsSearching(true);

    try {
      const response = await chat({
        conversation_id: conversationId,
        prompt: searchValue,
      }).unwrap();

      setResults((prevResults) => [...prevResults, response.response]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsSearching(false);
      setToneSelector(true);
    }
  };

  const {
    data,
    error,
    isLoading,
    refetch: refetchSavedChats,
  } = useGetChatsQuery();

  useEffect(() => {
    if (data) {
      const userId = getUser().id;
      const filteredItems = data.filter((item) => item.user === userId);

      setSaveItems(filteredItems);
    }
  }, [data]);

  const [deleteChat, { isLoading: isDeleting, error: deleteError }] =
    useDeleteChatMutation();

  const handleDelete = async (chat_id) => {
    try {
      await deleteChat({ chat_id }).unwrap();
      console.log('Chat deleted successfully');

      refetchSavedChats();
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const handleClosePopover = () => {
    setResults([]);
    setSearchValue('');
    setToneSelector(false);
    setShowSavedItems(false);
    setStatus(true);
    closePopover();
  };

  return (
    <div>
      <Header closePopover={handleClosePopover} />
      <Divider style={{ margin: 0, padding: 0 }} />
      <Layout style={LayoutContainer}>
        {showSavedItems ? (
          <ViewSaved
            saveItems={saveItems}
            closePopover={closePopover}
            handleDelete={handleDelete}
            handleInsertion={handleInsertion}
            toggleSavedItemsView={toggleSavedItemsView}
          />
        ) : (
          <>
            {status && results.length > 0 ? (
              <>
                <div style={ContentDisplay}>
                  {results.map((result, index) => (
                    <ContentGenerated
                      key={index}
                      result={result}
                      searchValue={searchValue}
                      conversationId={conversationId}
                      handleInsertion={handleInsertion}
                    />
                  ))}
                </div>

                {console.log('working', results)}
                {isSearching && <span>Working.......</span>}
                <ToneSelector backgroundColor="rgba(245, 245, 245, 1)" />
              </>
            ) : (
              <div>
                <Title level={5}>What do you want to say?</Title>
                <div
                  onClick={toggleSavedItemsView}
                  style={{ marginTop: '20px', cursor: 'pointer' }}
                >
                  <Space direction="horizontal" size="small">
                    <SaveIcon />
                    <span>View Saved</span>
                  </Space>
                </div>
                <Divider />
                <div style={{ marginTop: '20px' }}>
                  <ToneSelector />
                </div>
              </div>
            )}
            <InputBar onSearch={handleSearch} />
          </>
        )}
      </Layout>
    </div>
  );
}

const LayoutContainer = {
  width: '406px',
  paddingTop: '16px',
  paddingBottom: '16px',
  //paddingRight: '24px',
  paddingLeft: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.02)',
};

const ContentDisplay = {
  maxHeight: '30vh',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  maxWidth: '44vw',
  margin: '0 auto',
  padding: '10px',
  boxSizing: 'border-box',
};

export default PopoverContent;
