import { Avatar, Button, Drawer, Empty, Flex, Spin, Typography } from 'antd';
import {
  useGetNotificationsQuery,
  useMarkAllNotifcationsAsReadMutation,
} from '../../api/apiSlices/userApiSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../constants/routes';
import {
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation,
} from '../../api/apiSlices/socialMediaApiSlice';

import { openNotification } from '../../util/openNotifications';
import {
  useAcceptCommunityJoinReqMutation,
  useRejectCommunityJoinReqMutation,
} from '../../api/apiSlices/communityApiSlice';
import { useEffect } from 'react';
import { apiSlice } from '../../api/apiSlice';
import { useDispatch } from 'react-redux';
import { UserOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

export default function Notifications({ open, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    data: { notifications = [], unread_count = 0 } = {
      notifications: [],
      unread_count: 0,
    },
    isLoading,
    refetch: refetchNotifications,
  } = useGetNotificationsQuery(null, { refetchOnMountOrArgChange: true });
  const [markAllNotificationsAsRead] = useMarkAllNotifcationsAsReadMutation();
  const [acceptConnectionRequest, { isLoading: isAcceptingConnection }] =
    useAcceptConnectionRequestMutation();
  const [rejectConnectionRequest, { isLoading: isRejectingConnection }] =
    useRejectConnectionRequestMutation();
  const [acceptCommunityJoinReq, { isLoading: isAcceptingCommunity }] =
    useAcceptCommunityJoinReqMutation();
  const [rejectCommunityJoinReq, { isLoading: isRejectingCommunity }] =
    useRejectCommunityJoinReqMutation();

  useEffect(() => {
    if (unread_count) {
      markAllNotificationsAsRead().then(() => {
        dispatch(
          apiSlice.util.updateQueryData(
            'getNotifications',
            undefined,
            (draft) => {
              draft.unread_count = 0;
            },
          ),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unread_count]);

  const onAcceptConnectionReq = async (id) => {
    await acceptConnectionRequest(id)
      .unwrap()
      .then(() => {
        refetchNotifications();
        openNotification({
          type: 'success',
          message: 'Connection request accepted',
        });
      });
  };

  const onRejectConnectionReq = async (id) => {
    await rejectConnectionRequest(id)
      .unwrap()
      .then(() => {
        refetchNotifications();
        openNotification({
          type: 'success',
          message: 'Connection request rejected',
        });
      });
  };

  const onAcceptCommunityReq = async (id) => {
    await acceptCommunityJoinReq(id)
      .unwrap()
      .then(() => {
        refetchNotifications();
        openNotification({
          type: 'success',
          message: 'Community invite request accepted',
        });
      });
  };
  const onRejectCommunityReq = async (id, community_id) => {
    const body = { invitation_id: id, id: community_id };
    await rejectCommunityJoinReq(body)
      .unwrap()
      .then(() => {
        refetchNotifications();
        openNotification({
          type: 'success',
          message: 'Connection invite request rejected',
        });
      });
  };

  const mappedNotifications = notifications.map((notification) => {
    const notificationType = notification.notification_type;
    if (notificationType === 'user_connection_request') {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: '1px solid  #D9D9D9', paddingBottom: '12px' }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose()
              navigate(Routes.PROFILE.replace(":id", notification.content_object_details.sender_id))
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
              style={{ minWidth: 40 }}
              icon={<UserOutlined />}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details?.sender}
              </Title>
              <Text>Requested to connect with you</Text>
            </Flex>
          </Flex>
          <Flex gap={8} style={{ marginLeft: '50px' }}>
            <Button
              type="primary"
              primary
              onClick={() => {
                if (location.pathname.includes('profile')) {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      { type: 'user_connections' },
                    ]),
                  );
                }
                onAcceptConnectionReq(notification.object_id);
              }}
              disabled={isAcceptingConnection || isRejectingConnection}
            >
              Accept
            </Button>
            <Button
              type="default"
              primary
              onClick={() => {
                onRejectConnectionReq(notification.object_id);
              }}
              disabled={isAcceptingConnection || isRejectingConnection}
            >
              Reject
            </Button>
          </Flex>
        </Flex>
      );
    } else if (notificationType === 'community_join_request') {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: '1px solid  #D9D9D9', paddingBottom: '12px' }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose()
              navigate(Routes.COMMUNITY.replace(":id",  notification.content_object_details.community_id))
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
              style={{ minWidth: 40 }}
              icon={<UserOutlined />}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details.sender}
              </Title>
              <Text>{notification.message}</Text>
            </Flex>
          </Flex>
          <Flex gap={8} style={{ marginLeft: '50px' }}>
            <Button
              type="primary"
              primary
              onClick={async () => {
                onAcceptCommunityReq(notification.object_id);
              }}
              disabled={isAcceptingCommunity || isRejectingCommunity}
            >
              Accept
            </Button>
            <Button
              type="default"
              primary
              onClick={() => {
                onRejectCommunityReq(
                  notification.object_id,
                  notification.content_object_details.community_id,
                );
              }}
              disabled={isRejectingCommunity || isAcceptingCommunity}
            >
              Reject
            </Button>
          </Flex>
        </Flex>
      );
    } else if (
      notificationType === 'post_like' ||
      notificationType === 'post_comment'
    ) {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: '1px solid  #D9D9D9', paddingBottom: '12px' }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose();
              navigate(`/post/${notification.object_id}`);
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
              style={{ minWidth: 40 }}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details.sender}
              </Title>

              <Text>
                {notificationType === 'post_comment'
                  ? 'has commented on your post'
                  : 'has liked your post'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    } else if (notificationType === 'community_invitation_accepted') {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: '1px solid  #D9D9D9', paddingBottom: '12px' }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose();
              navigate(`/community/${notification.object_id}`);
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
              style={{ minWidth: 40 }}
              icon={<UsergroupDeleteOutlined />}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details.sender}
              </Title>

              <Text>{notification.message}</Text>
            </Flex>
          </Flex>
        </Flex>
      );
    }
    return <></>;
  });
  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title="Notifications"
      width={380}
    >
      <Spin spinning={isLoading}>
        <Flex vertical gap={12}>
          {mappedNotifications.length === 0 && (
            <Flex
              justify="center"
              align="center"
              vertical
              style={{ height: '100%', marginTop: '100px' }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Flex>
          )}
          {mappedNotifications}
        </Flex>
      </Spin>
    </Drawer>
  );
}
