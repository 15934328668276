import React, { useEffect, useState } from 'react';
import { Flex, Layout, Avatar, Dropdown } from 'antd';
import appLogoSmall from '../../assets/common/logoSmall.png';
import {
  BubbleChatIcon,
  FilledBubbleChatIcon,
  FilledHomeIcon,
  FilledNewPostIcon,
  FilledSearchIcon,
  HomeIcon,
  NewPostIcon,
  NotificationIcon,
  SearchIcon,
  CommunitiesIcon,
  CommunitiesSelectedIcon,
} from '../../assets/rawSvgs';
import { UserOutlined } from '@ant-design/icons';
import useRouterNavigation from '../../hooks/useNavigate';
import { Routes } from '../../constants/routes';
import { Link, useLocation } from 'react-router-dom';
import {
  getUser,
  removeJWTToken,
  removeRefreshToken,
  removeUser,
} from '../../api/auth';
import { setIsOpenCreatePost } from '../../redux/slices/socialMedia';
import { useDispatch } from 'react-redux';
import CreatePost from '../../pages/social-media/components/create-post/CreatePost';
import { useSelector } from 'react-redux';
import Notifications from './Notifications';
import SearchBar from './SearchBar';
import { useGetSocialMediaProfileQuery } from '../../api/apiSlices/socialMediaApiSlice';
import { useGetNotificationsQuery } from '../../api/apiSlices/userApiSlice';
import clsx from 'clsx';
import { apiSlice } from '../../api/apiSlice';
import './TopBar.scss';
import Step1 from '../../pages/Information-modal/Step1';
import Step3 from '../../pages/Information-modal/Step3';

const { Header } = Layout;

const TopBar = () => {
  const currentUser = getUser();
  const { data: userProfile } = useGetSocialMediaProfileQuery(currentUser?.id);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [isOpenSearchBar, setIsOpenSearchBar] = useState(false);
  const { navigateTo } = useRouterNavigation();
  const isOpenCreatePost = useSelector(
    (state) => state.socialMedia.isOpenCreatePost,
  );
  let dispatch = useDispatch();
  const { data: { unread_count } = { unread_count: 0 } } =
    useGetNotificationsQuery();

  useEffect(() => {
    if (window.pusher && currentUser?.id) {
      const channel = window.pusher.subscribe(`user-${currentUser.id}`);
      channel.bind('notification', function (data) {
        dispatch(
          apiSlice.util.updateQueryData(
            'getNotifications',
            undefined,
            (draft) => {
              draft.unread_count = draft.unread_count + 1;
              draft.notifications = [data, ...draft.notifications];
            },
          ),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  const handleLogOut = () => {
    removeJWTToken();
    removeRefreshToken();
    removeUser();

    navigateTo(Routes.LOGIN);
    window.location.reload(); // Force re-render
  };

  const handleIconClick = (iconName, route) => {
    setSelectedIcon(iconName);
    navigateTo(route);
  };

  const items = [
    {
      label: <Link to={Routes.SELECT_PLATFORM}>Switch Services</Link>,
      key: '0',
    },
    {
      label: (
        <Link to={Routes.PROFILE.replace(':id', currentUser?.id)}>Profile</Link>
      ),
      key: '1',
    },
    {
      label: <Link to={Routes.SETTINGS}>Settings</Link>,
      key: '2',
    },

    {
      label: <Link onClick={handleLogOut}>Logout</Link>,
      key: '3',
    },
  ];

  const onCloseCreatePost = ({ promises = [] }) => {
    dispatch(setIsOpenCreatePost(false));
    if (promises.length > 0) {
      Promise.all(promises);
    }
  };

  const openNotifications = () => setIsOpenNotifications(true);
  const closeNotificationsModal = () => setIsOpenNotifications(false);
  const openSearchBar = () => setIsOpenSearchBar(true);
  const closeSearchBar = () => setIsOpenSearchBar(false);
  const location = useLocation();

  const isRoute = (currentPath, targetRoutes) => {
    return targetRoutes.includes(currentPath);
  };

  return (
    <Header className="headerWrapper headerSocial">
      <Flex gap={48} align="center" justify="space-between">
        <div className="appLogo cursor-pointer">
          <img
            src={appLogoSmall}
            alt="app logo"
            onClick={() => navigateTo(Routes.HOME)}
          />
        </div>
        <Flex gap={32} className="navItems">
          <div
            className="cursor-pointer"
            onClick={() => handleIconClick('home', Routes.HOME)}
          >
            {isRoute(location.pathname, Routes.HOME) ? (
              <FilledHomeIcon />
            ) : (
              <HomeIcon />
            )}
          </div>
          <div className="cursor-pointer" onClick={openSearchBar}>
            {selectedIcon === 'search' ? <FilledSearchIcon /> : <SearchIcon />}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleIconClick('communities', Routes.COMMUNITIES)}
          >
            {isRoute(location.pathname, Routes.COMMUNITIES) ? (
              <CommunitiesSelectedIcon />
            ) : (
              <CommunitiesIcon />
            )}
          </div>
          <div
            onClick={() => handleIconClick('bubbleChat', Routes.CHAT)}
            className="cursor-pointer"
          >
            {selectedIcon === 'bubbleChat' ? (
              <FilledBubbleChatIcon />
            ) : (
              <BubbleChatIcon />
            )}
          </div>
          <div
            onClick={() => dispatch(setIsOpenCreatePost(true))}
            className="cursor-pointer"
          >
            {selectedIcon === 'newPost' ? (
              <FilledNewPostIcon />
            ) : (
              <NewPostIcon />
            )}
          </div>
          <div
            onClick={openNotifications}
            className={clsx('cursor-pointer', 'notification-bell')}
          >
            <NotificationIcon />
            {unread_count > 0 && (
              <span className="unread-notifications">
                {unread_count > 99 ? '99+' : unread_count}
              </span>
            )}
          </div>
        </Flex>
        <div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={['click']}
            overlayStyle={{ width: '165px' }}
          >
            <a onClick={(e) => e.preventDefault()} href="*">
              <Avatar
                size="default"
                icon={<UserOutlined />}
                className="cursor-pointer"
                style={{
                  border:
                    selectedIcon === 'profile' ? '1px solid #3DC1DC' : 'none',
                  boxShadow:
                    selectedIcon === 'profile'
                      ? '0px 0px 0px 2px rgba(24, 144, 255, 0.20)'
                      : 'none',
                }}
                src={userProfile?.profile_picture?.url}
              />
            </a>
          </Dropdown>
        </div>
      </Flex>

      {isOpenCreatePost && <CreatePost onClose={onCloseCreatePost} />}
      {isOpenNotifications && (
        <Notifications
          open={isOpenNotifications}
          onClose={closeNotificationsModal}
        />
      )}
      {isOpenSearchBar && (
        <SearchBar open={isOpenSearchBar} onClose={closeSearchBar} />
      )}

      {/* <Step1/> */}
      {/* ?<Step3/> */}
    </Header>
  );
};

export default TopBar;
