import { Col, Flex, Menu, Row } from "antd"
import Title from "antd/es/typography/Title"
import { useEffect, useState } from "react"
import tabComponents from "./tabs/tabComponents"
import CustomInput from "../../components/customInput/CustomInput"
import { SearchOutlined } from "@ant-design/icons"

import { useLocation, useNavigate } from "react-router-dom"

const items = [
  {
    key: "account-privacy",
    label: "Account Privacy",
    content:"Lock Profile Only friends will see the photos and posts on your profile. Profile Visibility Choose what info to display on your profile.Profile Visibility Settings Preview Profile Maximize Profile Privacy"
  },
  {
    key: "connect-social-accounts",
    label: "Connect Social Accounts",
    content:"Cross Platform Post Sharing.Share your posts seamlessly across connected accounts.Connect Account. Remove"
   
  },
  {
    key: "consent-management",
    label: "Consent Management"
    
  },
  {
    key: "account-deletion",
    label: "Account Deletion",
    content:" Are you sure you want to delete this account? This action cannot be undone. Delete Account.Permanently delete your account and all associated data"
  },
  {
    key: "feedback",
    label: "Feedback and Support"
  },
  {
    key: "donations",
    label: "Donations History"
  }
]

const Settings = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [currentTabIndex, setcurrentTabIndex] = useState("account-privacy")
  const [searchTerm , setSearchTerm]=useState("")

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tab = searchParams.get("tab")
    if (tab) {
      setcurrentTabIndex(tab)
      searchParams.delete("tab")
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true
      }) // Update the URL without reloading the page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const filteredItems = items.filter(item => {
    const matchesLabel = item.label.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesContent = item.content && item.content.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesLabel || matchesContent;
  });
  const currentTabLabel = items.find(item => item.key === currentTabIndex).label
  const CurrentTabComponent = tabComponents[currentTabIndex]

  const handleSearch =(e)=>{
    setSearchTerm(e.target.value)
  }
  return (
    <Row gutter={[48, 48]}>
      <Col xl={7} xxl={5}>
        <Flex vertical gap={32}>
          <div className="">
            <Title level={2} className="mb-3" aria-label="Settings">
              Settings
            </Title>
            <CustomInput
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
            />
          </div>
          <Menu
            style={{ width: "100%" }}
            defaultSelectedKeys={currentTabIndex}
            defaultOpenKeys={currentTabIndex}
            selectedKeys={[currentTabIndex]}
            mode="vertical"
           // items={items}
           items={filteredItems.map(item => ({
            key: item.key,
            label: item.label,
          }))}
          onSelect={event => {
            setcurrentTabIndex(event.key);
            navigate(`?tab=${event.key}`); 
          }}
          />
        </Flex>
      </Col>
      <Col xl={17} xxl={19}>
        <Title level={4} aria-label={currentTabLabel} className="mb-4">
          {currentTabLabel}
        </Title>
        <CurrentTabComponent />
      </Col>
    </Row>
  )
}

export default Settings
