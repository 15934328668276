import React, { useState } from 'react';
import { Avatar, Button, Empty, Flex, Modal, Typography } from 'antd';

import { ChatIcon } from '../../../../assets/rawSvgs';
import CustomInput from '../../../../components/customInput/CustomInput';

import {
  useDeleteUserConnectionMutation,
  useGetUserConnectionsQuery,
} from '../../../../api/apiSlices/socialMediaApiSlice';

import { useDebounce } from '../../../../hooks/useDebounce';

import {
  ExclamationCircleOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons"
import { Routes } from "../../../../constants/routes"
import { useNavigate } from "react-router-dom"
import useRouterNavigation from "../../../../hooks/useNavigate"
import { openNotification } from "../../../../util/openNotifications"
import { useCreateSocialUserChatMutation } from "../../../../api/apiSlices/socialMediaApiSlice"
import { useParams } from "react-router-dom"

const { Text } = Typography;

const UserConnectionManagment = () => {

  const { navigateTo } = useRouterNavigation()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  //let { id: userId } = useParams()
 
  const debouncedSearchTerm = useDebounce(search, 500)

  const [modal, contextHolder] = Modal.useModal();

  const { data: connections, refetch: refetchConnections } =
    useGetUserConnectionsQuery( {searchTerm: debouncedSearchTerm}, {
      refetchOnMountOrArgChange: true
    })
    //console.log("memberid",connections.connected_user.user_id)
    console.log(connections);

    const [createChat, { isLoading: isChatLoading }] =
    useCreateSocialUserChatMutation()

  const [deleteUserConnection] = useDeleteUserConnectionMutation();

  const showModal = () => {
    if (connections?.length === 0) return;
    setOpen(true);
  };

  const handleDeleteConnection = async (id) => {
    await deleteUserConnection(id);
    refetchConnections();
  };

  const showRemoveModal = (id) => {
    modal.confirm({
      title: 'Remove Friend',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you want to remove this account? This action cannot be undone.',
      okText: 'Remove',
      cancelText: 'Cancel',
      okButtonProps: { danger: true },
      onOk: () => handleDeleteConnection(id),
      centered: true
    })
  }

  const handleCreateChat = async (userid) => {
    try {
      const payload = {
       member_id: userid
       
      }
     // console.log(connections.connected_user.user_id)
      const chat = await createChat(payload).unwrap()
      navigateTo(`${Routes?.CHAT}?channel_id=${chat?.channel_id}`)
      openNotification({
        type: "success",
        message: "Chat created successfully!"
      })
    } catch (error) {
      openNotification({
        type: "error",
        message:
          error?.message ||
          error?.errors ||
          "Unexpected error occurred. Please try again."
      })
    }
  }
  return (
    <>
      <Modal
        open={open}
        centered
        title="Friends"
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div className="feedbackModal">
          <Flex vertical gap="middle">
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {connections?.map((connection, index) => {
              
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex
                    gap="small"
                    onClick={() => {
                      navigate(
                        Routes.PROFILE.replace(
                          ':id',
                          connection.connected_user.user_id,
                        ),
                      );
                      setOpen(false);
                    }}
                  >
                    <Avatar
                      size="small"
                      icon={<UserOutlined />}
                      src={connection?.connected_user?.profile_picture?.url}
                    />
                    <Text>
                      {connection?.connected_user?.name || 'Anonymous'}
                    </Text>
                  </Flex>
                  <Flex gap="small">
                    <Button icon={<ChatIcon />}  onClick={()=>handleCreateChat(connection.connected_user.user_id)}
                  disabled={isChatLoading} size="medium" />
                    <Button
                      onClick={() => showRemoveModal(connection?.connection_id)}
                    >
                      Remove
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
            {connections?.length === 0 && search?.length !== 0 && (
              <Flex justify="center" align="center" vertical>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No results found against this search"
                />
              </Flex>
            )}
          </Flex>
        </div>
      </Modal>
      <Text onClick={showModal} underline className="text-lg-500">
        {connections?.length || 0} Friends
      </Text>
      {contextHolder}
    </>
  );
};

export default UserConnectionManagment;
