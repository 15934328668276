import React from 'react';
import axios from 'axios';
import { Input, Button, Form, Typography, Flex, notification } from 'antd';

const { TextArea } = Input;
const { Title, Text } = Typography;

const ContactUs = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post('/api/v1/contact-us/', values);
      console.log('Success:', response.data);
      notification.success({
        message: 'Success',
        description: 'Your message has been sent successfully!',
      });
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'Error',
        description:
          'There was an error sending your message. Please try again later.',
      });
    }
  };
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    handleSubmit(values);
  };

  return (
    <div>
      <Flex justify="center">
        <div style={HeaderStyle}>
          <Title style={{ color: '#8A41D9' }}>Contact Us</Title>
        </div>
      </Flex>

      <Flex align="center" justify="center" direction="vertical">
        <div>
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Text
              style={{ fontSize: '19px', fontWeight: '400', color: '#030303' }}
            >
              Our team is here to help with any inquiries or bookings. Contact
              today and experience the best services
            </Text>
          </div>
        </div>
      </Flex>

      <div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <div>
            <div>
              <Form.Item
                label="Name"
                name="Name"
                colon={false}
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Phone Number"
                name="PhoneNumber"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                    pattern:
                      /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
                    message: 'Enter a valid phone number',
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Email"
                name="Email"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                    type: 'email',
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
          </div>
          <div>
            <Form.Item label="Message" name="Message" colon={false}>
              <TextArea placeholder="Message" rows={4} />
            </Form.Item>
          </div>
          <div>
            <Form.Item>
              <Button
                style={ButtonStyle}
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                Send
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

const ButtonStyle = {
  backgroundColor: '#8A41D9',
  fontSize: '15px',
  height: '40px',
  padding: '12px 24px',
  borderRadius: '3px',
  fontWeight: '500',
};

const HeaderStyle = {
  paddingTop: '240px',
  paddingBottom: '120px',
  backgroundImage:
    'url(https://auxriders.com/wp-content/uploads/2024/06/Untitled-design-84.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  textAlign: 'center',
};

export default ContactUs;
