import "./styles.scss"
import SettingsSection from "../components/settings-section/SettingsSection"
import { EyeOutlined, LockOutlined, UserOutlined } from "@ant-design/icons"
import GoToButton from "../../../components/go-to-button"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import VisibilitySettingsModal from "../components/visibility-settings/VisibilitySettingsModal"
import { Spin, Switch } from "antd"
import { openNotification } from "../../../util/openNotifications"
import {
  useFetchVisibilitySettingsQuery,
  useUpdateVisibilitySettingsMutation
} from "../../../api/apiSlices/settingsApiSlice"
import { getUser } from "../../../api/auth"

const AccountPrivacy = () => {
  let navigate = useNavigate()
  const currentUser = getUser()
  const [isOpenVisibilitySetting, setIsOpenVisibilitySettings] = useState(false)
  const {
    data: visibilitySettings,
    refetch,
    isLoading: isLoadingVisibilitySettings
  } = useFetchVisibilitySettingsQuery()
  const [updateVisibilitySettings] = useUpdateVisibilitySettingsMutation()

  const isMaximumVisibilitySettingsApplied =
    !isLoadingVisibilitySettings &&
    !visibilitySettings.show_profile_picture &&
    !visibilitySettings.show_user_name &&
    !visibilitySettings.show_age &&
    !visibilitySettings.show_user_name &&
    !visibilitySettings.show_friends_list

  const maximizeVisibilitySettings = () => {
    if (isMaximumVisibilitySettingsApplied) {
      setIsOpenVisibilitySettings(true)
      return
    }
    updateVisibilitySettings({
      show_profile_picture: false,
      show_user_name: false,
      show_age: false,
      show_gender: false,
      show_friends_list: false
    }).then(() => {
      refetch()
      openNotification({ type: "success", message: "Maximum Privacy Applied" })
    })
  }

  const toggleProfileLockStatus = () => {
    if (isLoadingVisibilitySettings) return
    updateVisibilitySettings({
      is_profile_locked: !visibilitySettings.is_profile_locked
    }).then(() => {
      openNotification({
        type: "success",
        message: visibilitySettings.is_profile_locked
          ? "You've unlocked your profile"
          : "You've locked your profile",
        description: visibilitySettings.is_profile_locked
          ? "You have made your photos and posts on your profile visible to public"
          : "Only your friends can see the photos and posts on your profile."
      })
      refetch()
    })
  }

  const goToPreviewProfile = () =>
    navigate(`/profile/${currentUser.id}`, { state: { applyVisibilitySettings: true } })

  console.log("current user", currentUser.id)

  return (
    <div className="tabContainer">
      <Spin spinning={isLoadingVisibilitySettings}>
        <SettingsSection
          title="Lock Profile"
          description="Only friends will see the photos and posts on your profile."
        >
          <div className="actionSection">
            <div className="deleteBtnWrapper">
              <Switch
                value={visibilitySettings?.is_profile_locked}
                onChange={toggleProfileLockStatus}
              />
            </div>
          </div>
        </SettingsSection>
        <SettingsSection
          title="Profile Visibility"
          description="Choose what info to display on your profile."
        >
          <div className="actionSection actionSectionVertical">
            <div className="deleteBtnWrapper">
              <GoToButton
                title="Profile Visibility Settings"
                icon={UserOutlined}
                onClick={() => setIsOpenVisibilitySettings(true)}
              />
            </div>
            <div className="deleteBtnWrapper">
              <GoToButton
                title="Preview Profile"
                icon={EyeOutlined}
                onClick={goToPreviewProfile}
              />
            </div>
            {isOpenVisibilitySetting && (
              <VisibilitySettingsModal
                onClose={() => setIsOpenVisibilitySettings(false)}
              />
            )}
          </div>
        </SettingsSection>
        <SettingsSection title="Maximize Profile Privacy">
          <div className="actionSection actionSectionVertical">
            <div className="deleteBtnWrapper">
              <Switch
                value={isMaximumVisibilitySettingsApplied}
                onChange={maximizeVisibilitySettings}
              />
            </div>
          </div>
        </SettingsSection>
      </Spin>
    </div>
  )
}

export default AccountPrivacy
