import ForgotPassword from '../pages/forgot-password';
import Login from '../pages/login';
import OtpScreen from '../pages/otp-screen';
import ResetPassword from '../pages/reset-password';
import TwoFactorAuthentication from '../pages/two-factor-authentication';
import Register from '../pages/register';
import PublicMessage from '../pages/public-message/PublicMessage';
import PrivacyPolicy from '../pages/privacy-policy';
import TermsAndConditions from '../pages/terms-and-conditions';
import LandingPage from '../pages/landing-page';
import ContactUs from '../pages/contact-us';

import { Routes } from '../constants/routes';
import AdminChat from '../pages/chat-management/AdminChat';

const routeObjects = [
  {
    path: Routes.LANDING,
    component: LandingPage,
  },
  {
    path: Routes.LOGIN,
    component: Login,
  },
  {
    path: Routes.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: Routes.contact,
    component: ContactUs,
  },

  {
    path: Routes.OTP,
    component: OtpScreen,
  },
  {
    path: Routes.TWOFA,
    component: TwoFactorAuthentication,
  },
  {
    path: Routes.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: Routes.REGISTER,
    component: Register,
  },
  {
    path: Routes.PUBLIC_MESSAGE,
    component: PublicMessage,
  },
  {
    path: Routes.PRIVACY_POLICY,
    component: PrivacyPolicy,
  },
  {
    path: Routes.ADMIN_CHAT,
    component: AdminChat,
  },
  { path: Routes.TERMS_AND_CONDITION, component: TermsAndConditions },
  { path: '*', component: Login },
];

export default routeObjects;
//https://all-good-people-48318-staging.azurewebsites.net/admin-chat/?admin_id=11&channel_id=J16-Admin-W277
