import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Modal,
  Spin,
  Typography,
  Upload,
} from 'antd';
import {
  ExclamationCircleOutlined,
  MessageOutlined,
  MoreOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { ViewInvitations } from '../view-invitations/ViewInvitations';
import {
  useCancelCommunityReqMutation,
  useCreateCommunityMutation,
  useDeleteCommunityMutation,
  useGetCommunityDetailsQuery,
  useJoinCommunityMutation,
  useLeaveCommunityMutation,
} from '../../../../api/apiSlices/communityApiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsCommunityAdmin } from '../../../../util/useIsCommunityAdmin';
import { openNotification } from '../../../../util/openNotifications';
import { useAddMediaMutation } from '../../../../api/apiSlices/settingsApiSlice';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../../api/apiSlice';
import { useIsPartOfCommunity } from '../../../../util/useIsPartOfCommunity';
import CreateCommunityModal from '../../../../components/communities-manage-banner/CreateCommunityModal';
import clsx from 'clsx';
import './Header.scss';
import Members from '../members/Members';
import { Routes } from '../../../../constants/routes';
import InviteMembers from '../invite-members/InviteMembers';
import { useCreateSocialUserChatMutation } from '../../../../api/apiSlices/socialMediaApiSlice';
import useRouterNavigation from '../../../../hooks/useNavigate';
import { UsergroupDeleteOutlined } from '@ant-design/icons';
import placeholder4 from '../../../../assets/images/placeholder.jpg';

const { Title, Text } = Typography;

export default function Header() {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  let { id: communityId } = useParams();
  const { navigateTo } = useRouterNavigation();
  const dispatch = useDispatch();

  const {
    data: communityDetails,
    isLoading,
    refetch: refetchCommunityDetails,
  } = useGetCommunityDetailsQuery(communityId, {
    refetchOnMountOrArgChange: true,
  });

  const [addMedia, { isLoading: isUploadingCoverImage }] =
    useAddMediaMutation();
  const [cancelReq] = useCancelCommunityReqMutation();

  const [deleteCommunity] = useDeleteCommunityMutation();

  const [joinCommunity, { isLoading: isJoinInProgress }] =
    useJoinCommunityMutation();
  const [
    isJoined,
    refetchJoinedCommunities,
    isLoadingJoinedCommunities,
    isFetchingJoinedCommunities,
  ] = useIsPartOfCommunity(communityId);
  const [createCommunity] = useCreateCommunityMutation();

  const [createChat, { isLoading: isChatLoading }] =
    useCreateSocialUserChatMutation();

  const isAdmin = useIsCommunityAdmin(communityDetails?.admins, isLoading);

  const [invitationsViewModal, setViewInvitationsModal] = useState(false);
  const [isOpenEditCommunityDetailsModal, setIsOpenEditCommunityDetailsModal] =
    useState(false);

  const [leaveCommunity] = useLeaveCommunityMutation();

  const handleLeaveCommunity = async () => {
    await leaveCommunity(communityId)
      .unwrap()
      .then(() => {
        openNotification({
          type: 'info',
          message: 'Done',
        });
        navigate(Routes.COMMUNITIES);
      });
  };

  const onDeleteCommunity = () => {
    modal.confirm({
      title: 'Delete Community',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you want to leave this community? This action cannot be undone.',
      okText: 'Remove',
      cancelText: 'Cancel',
      okButtonProps: { danger: true },
      onOk: () => {
        deleteCommunity(communityId)
          .unwrap()
          .then(() => {
            dispatch(apiSlice.util.invalidateTags(['managed_communities']));
            navigate(Routes.HOME);
          })
          .catch((error) => {
            openNotification({
              type: 'error',
              message: error?.message || 'Failed to delete',
            });
          });
      },
      centered: true,
    });
  };

  const onLeaveCommunity = () => {
    modal.confirm({
      title: 'Leave Community',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you want to leave this community? This action cannot be undone.',
      okText: 'Leave',
      cancelText: 'Cancel',
      okButtonProps: { danger: true },
      onOk: handleLeaveCommunity,
      centered: true,
    });
  };

  const onEditCommunity = () => {
    if (!isAdmin) return;
    setIsOpenEditCommunityDetailsModal(true);
  };

  const items = [
    { label: <span onClick={onEditCommunity}>Edit Community Info</span> },
    { label: <span onClick={onDeleteCommunity}>Delete Community</span> },
  ];

  const joinedUserItems = [
    { label: <span onClick={onLeaveCommunity}>Leave Community</span> },
  ];

  const handleCoverUpload = async ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const data = await addMedia(formData).unwrap();
      await createCommunity({
        cover_image: data.object_key,
        community_id: communityId,
      });
      refetchCommunityDetails();
    } catch (error) {
      openNotification({ type: 'error', message: error?.message });
    }
  };

  const onJoinCommunity = () => {
    if (isAdmin || isJoined) return;
    joinCommunity({ id: communityId })
      .unwrap()
      .then(() => {
        const visibility = communityDetails.visibility;
        openNotification({
          type: 'success',
          message:
            visibility === 'public'
              ? 'Joined!'
              : 'An invitation has been sent to the community admin',
        });
        if (visibility === 'public') {
          refetchJoinedCommunities();
          dispatch(apiSlice.util.invalidateTags(['community_members']));
        }
        refetchCommunityDetails();
      })
      .catch((error) => {
        openNotification({ type: 'error', message: error?.message });
      });
  };

  const onViewInvitations = () => {
    setViewInvitationsModal(true);
  };

  const handleCreateChat = async () => {
    try {
      const payload = {
        community_id: communityId,
      };
      const chat = await createChat(payload).unwrap();
      navigateTo(`${Routes?.CHAT}?channel_id=${chat?.channel_id}`);
      openNotification({
        type: 'success',
        message: 'Chat created successfully!',
      });
    } catch (error) {
      openNotification({
        type: 'error',
        message:
          error?.message ||
          error?.errors ||
          'Unexpected error occurred. Please try again.',
      });
    }
  };

  const onCloseInvitationsModal = () => setViewInvitationsModal(false);

  const onSuccessCommunityEdit = () => {
    setIsOpenEditCommunityDetailsModal(false);
    refetchCommunityDetails();
  };

  const cancelCommunityReq = async () => {
    await cancelReq({ community_id: communityId })
      .unwrap()
      .then(() => {
        refetchCommunityDetails();
        openNotification({
          type: 'success',
          message: 'Request removed',
        });
      })
      .catch((error) => {
        openNotification({ type: 'error', message: error?.message });
      });
  };

  const memberIds = communityDetails?.members?.map((member) => member.user_id);

  return (
    <>
      <div
        className="header"
        style={{
          backgroundImage: `url(${communityDetails?.cover_image?.url || placeholder4}`,
          backgroundPosition: '50% 35%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {!communityDetails?.cover_image?.url && (
          <PictureOutlined style={{ fontSize: 64, color: '#d9d9d9' }} />
        )}
        <div className="community-avatar" onClick={onEditCommunity}>
          <Avatar
            size="large"
            className="avatar"
            src={communityDetails?.display_image?.url}
            icon={<UsergroupDeleteOutlined />}
          />
        </div>
        <div
          className={clsx(
            'upload-cover',
            communityDetails?.cover_image && 'upload-cover-btn-hidden',
          )}
        >
          <Spin spinning={isUploadingCoverImage}>
            {isAdmin && (
              <Upload
                customRequest={handleCoverUpload}
                showUploadList={false}
                disabled={!isAdmin}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />} type="default">
                  {communityDetails?.cover_image
                    ? 'Update Cover Image'
                    : 'Upload Cover Image'}
                </Button>
              </Upload>
            )}
          </Spin>
        </div>
      </div>
      <div className="header-actions">
        <Flex vertical>
          <Title level={5}>{communityDetails?.name}</Title>
          <Flex gap={8} align="center">
            <Text style={{ textTransform: 'capitalize' }}>
              {communityDetails?.visibility} Community
            </Text>
            {(communityDetails?.visibility === 'public' || isAdmin) && (
              <>
                <div className="dot dot_small" />
                <Members
                  memberCount={communityDetails?.member_count}
                  communityId={communityId}
                  isAdmin={isAdmin}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Flex gap={8}>
          {(isAdmin || isJoined) && (
            <Button
              icon={<MessageOutlined />}
              onClick={handleCreateChat}
              disabled={isChatLoading}
            >
              Community Chat
            </Button>
          )}

          {(isAdmin || isJoined) &&
            communityDetails?.visibility !== 'public' && (
              <InviteMembers communityId={communityId} memberIds={memberIds} />
            )}
          {isAdmin && communityDetails.visibility === 'private' && (
            <Button icon={<UserAddOutlined />} onClick={onViewInvitations}>
              View Join Requests
            </Button>
          )}
          {!isJoined &&
            !isAdmin &&
            !communityDetails?.has_requested_to_join && (
              <Spin spinning={isJoinInProgress || isFetchingJoinedCommunities}>
                <Button icon={<UserAddOutlined />} onClick={onJoinCommunity}>
                  Join Community
                </Button>
              </Spin>
            )}
          {communityDetails?.has_requested_to_join && (
            <Button icon={<UserDeleteOutlined />} onClick={cancelCommunityReq}>
              Cancel Join Request
            </Button>
          )}
          {isAdmin && (
            <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          )}
          {isJoined && (
            <Dropdown
              menu={{
                items: joinedUserItems,
              }}
              trigger={['click']}
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </Flex>
      </div>
      <div className="community-description">
        <Text className="text">{communityDetails?.description}</Text>
      </div>
      {invitationsViewModal && (
        <ViewInvitations
          open={invitationsViewModal}
          onClose={onCloseInvitationsModal}
          communityId={communityId}
          refetchCommunityDetails={refetchCommunityDetails}
        />
      )}
      {isOpenEditCommunityDetailsModal && (
        <CreateCommunityModal
          onCancel={() => setIsOpenEditCommunityDetailsModal(false)}
          community={communityDetails}
          onSuccess={onSuccessCommunityEdit}
        />
      )}
      {contextHolder}
    </>
  );
}
