import React, { useState, useEffect } from 'react';
import { Typography, Button, Space, Flex, Divider, Spin } from 'antd';
import { SaveIcon } from '../../../../../assets/rawSvgs';
import { DeleteIcon3 } from '../../../../../assets/rawSvgs';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Header from './Header';

const { Title, Text } = Typography;

function ViewSaved({ saveItems, closePopover, handleDelete, handleInsertion, toggleSavedItemsView }) {
  const [deletingId, setDeletingId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (saveItems) {
      setLoading(false);
    }
  }, [saveItems]);

  const handleDeleteClick = async (id) => {
    setDeletingId(id);
    await handleDelete(id);
    setDeletingId(null);
  };

  console.log('saveItems', saveItems);

  return (
    <>
      <div className='saved-items-container' style={savedItemsStyle}>
        {/* <Header closePopover={closePopover}></Header> */}
        <>
          <div style={headerStyle}>
            <ArrowLeftOutlined onClick={() => toggleSavedItemsView()} />
            <Title level={5}>Saved</Title>
          </div>
        </>
        {loading ? (
          <Spin size="large" />
        ) : saveItems.length === 0 ? (
          <div style={{ 
          
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '18vh', 
    
            
          paddingLeft: '150px', paddingRight: '150px', textAlign: 'center' }}>
            <Text>No saved chats</Text>
          </div>  
        ) : (
          saveItems.map((item, index) => (
            <div key={index} style={{ marginBottom: '10px', marginTop: '24px', maxWidth: 'auto' }}>
              <div style={{ wordWrap: 'break-word' }}>
                <Space direction="vertical">
                  <Text type="secondary">{item.prompt}</Text>
                  <Text>{item.response}</Text>

                  <div>
                    <Flex gap='250px'>
                      <Button style={{ borderColor: '#3DC2DC', color: '#3DC2DC', backgroundColor: '#FFFFFF' }} onClick={() => handleInsertion(item.response)}>Insert</Button>
                      <span onClick={() => handleDeleteClick(item.id)} style={{ cursor: 'pointer' }}>
                        {deletingId === item.id ? <Spin size="small" /> : <DeleteIcon3 style={{ color: 'red' }} />}
                      </span>
                    </Flex>
                    <Divider />
                  </div>
                </Space>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

const containerStyle = {
  paddingLeft: '20px',
  backgroundColor: 'red'
};

const savedItemsStyle = {
  maxHeight: '200px',
  overflowY: 'auto',
  scrollbarWidth: 'thin', // For Firefox
  scrollbarColor: '#F0F0F0 transparent', // For Firefox
};

// Scrollbar styles for Webkit-based browsers (Chrome, Edge, Safari)
const customScrollbarStyle = `
  .saved-items-container::-webkit-scrollbar {
    width: 8px;
    height: 87px;
  }
  .saved-items-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
  }
  .saved-items-container::-webkit-scrollbar-thumb {
    background-color: #F0F0F0;
    border-radius: 100px;
  }
`;

const headerStyle = {
  gap: '20px',
  display: 'Flex',
  alignItems: 'center'
};

export default ViewSaved;
