import React from 'react'
import { Typography, Button, Space, Flex, Divider, Spin } from 'antd'
import { useState } from 'react'
import { SaveIcon } from '../../../../../assets/rawSvgs'
import { AfterSave } from '../../../../../assets/rawSvgs'
import { useSaveChatMutation } from '../../../../../api/apiSlices/communityApiSlice'
import { openNotification } from '../../../../../util/openNotifications'

const { Text } = Typography

function ContentGenerated({ result, onSave, handleInsertion, searchValue, prompt, conversationId }) {
  const [isSaved, setIsSaved] = useState(false)
  const [saveChat, { isLoading, error }] = useSaveChatMutation()
  const [isSaving, setIsSaving] = useState(false)

  const handleSaveClick = async () => {
    try {
      setIsSaving(true)

      const response = await saveChat({
        conversation_id: conversationId,
        prompt: searchValue,
        response: result
      }).unwrap()

      setIsSaved(true)
    } catch (error) {
      openNotification({
        type: "error",
        message: "Error saving chat: " + (error?.message || "Unknown error")
      })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
      <Space direction="vertical" size="small">
        <Text type="secondary">Tell a one line story?</Text>
        <Text>{result}</Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Flex gap='250px'>
            <div>
              <Button onClick={() => handleInsertion(result)}>Insert</Button>
            </div>
            <div
              style={{
                cursor: isSaved ? 'default' : 'pointer',
                pointerEvents: isSaved ? 'none' : 'auto'
              }}
              onClick={!isSaved ? () => { handleSaveClick() } : undefined}
            >
              {isSaving ? (
                <Spin size="small" />
              ) : isSaved ? (
                <AfterSave />
              ) : (
                <SaveIcon />
              )}
            </div>
          </Flex>
        </div>
      </Space>
      <Divider />
    </div>
  )
}

export default ContentGenerated


